/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/chart.js@3.9.1/dist/chart.min.js
 * - /npm/chartjs-plugin-deferred@2.0.0/dist/chartjs-plugin-deferred.min.js
 * - /npm/chartjs-plugin-stacked100@1.5.3/build/index.min.js
 * - /npm/pinch-zoom-js@2.3.5/dist/pinch-zoom.umd.min.js
 * - /npm/@fancyapps/ui@4.0.31/dist/fancybox.umd.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
